import { formRules } from "../../../../config/validations/formRules";
import { validations } from "../../../../config/validations/validations";


export const rules: any = {
	name: () => formRules.first_name(),
	last_name: () => formRules.last_name(),
	email: (field?: string) => [
		validations.required.text(field),
		validations.email(field),
	],
	access_code: (field?: string) => [
		validations.required.text(field),
		validations.max.number(15),
		validations.min.number(5),
	],
	password: (field?: any) => [
		validations.max.number(50),
		validations.required.text(field),
		validations.pattern.password(field),

	],
	password_confirmation: (field?: any) => [
		validations.required.text(field),
		validations.max.number(50),
		({ getFieldValue }: any) => ({
			validator() {
				const password = getFieldValue("password");
				const password_confirmation = getFieldValue("password_confirmation");

				if (password_confirmation) {
					if (password.trim() === password_confirmation) {
						return Promise.resolve();
					} else {
						return Promise.reject("Password does not match.");
					}
				}
				return Promise.resolve();
			},
		}),
	],
	mobile: () => [
		validations.pattern.phone,
		validations.min.number(10),
		validations.max.number(20)

	],
	tearmandconditiom:(field?: any)=>[
		validations.required.select(field)
	]
};
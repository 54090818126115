import { Button, Col, Row, Space, Table, Typography, Input, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'

import BreadcrumbComponent from '../../../CommonComponent/BreadcrumbComponent';
import { AllCareerBreadcrumb } from '../../../../../config/BreadcrumbConfig';
import { CareerSubBreadcrumb } from '../../../../../config/BreadcrumbConfig';
import { ReactComponent as RightArrow } from "../../../../../assets/images/icon/dashboardRightArrow.svg";

import { AppDispatch, RootState } from '../../../../../store/app';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCareers, addRemoveJob, fetchCareerCategorys, fetchCareerCategoryDetails } from '../Utils/slice';
import { Link, redirect,  useParams } from 'react-router-dom';
import { ArrowRightOutlined, FileDoneOutlined, SearchOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import CareerDetailsModal from './components/CareerDetailsModal';
import PageSpinner from '../../../../../components/PageSpinner/PageSpinner';
const { Title } = Typography;


/**
 * Renders component for All Careers .
 *
 * @return {JSX.Element} The rendered component.
 */
const Index = () => {
    const { career_category } = useParams();
    // console.log(career_category);

    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch: AppDispatch = useDispatch();
    const careerList = useSelector((state: RootState) => state.WEBPROFILE.careerList);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [searchValue, setSearchValue] = useState("");
    const [inputValue, setInputValue] = useState("");

    const [detailsModalId, setDetailsModalId] = useState<any>(null);
    const [CarierCategoryList, setCarierCategoryList] = useState<any>([]);

    /*  const CarierCategoryList = [
         {
             id: 1,
             title: 'Strategist',
             slug: 'strategist'
         },
         {
             id: 2,
             title: 'Engineers',
             slug: 'engineers'
         },
         {
             id: 3,
             title: 'Operators',
             slug: 'operators'
         },
         {
             id: 4,
             title: 'Analysts',
             slug: 'analysts'
         },
         {
             id: 5,
             title: 'Implementors',
             slug: 'implementors'
         },
         {
             id: 6,
             title: 'Trades',
             slug: 'trades'
         },
     ] */




    useEffect(() => {

        setPageLoading(true);
        dispatch(fetchCareerCategorys()).then((data: any) => {
            setLoading(false)
            setPageLoading(false);
            setCarierCategoryList(data.data);
        }).catch(() => {
            setCarierCategoryList([]);
            setLoading(false)
            setPageLoading(false);
        });


        if (career_category != undefined) {
            // console.log('career_category_id', career_category);
            
            if (career_category == 'all') {
                setSelectedCategory(career_category);
                setPageLoading(false);
            }
            else {
                getCareerCategoryDetails(atob(career_category));
            }
        }
        else {
            setSelectedCategory(null);
        }
        setSearchValue('')

    }, [career_category])



    const getCareerCategoryDetails = (career_category_id: any) => {

        dispatch(fetchCareerCategoryDetails(career_category_id)).then((response: any) => {
            setPageLoading(false);
            if (!response.data) {
                redirect('careers');
            }
            setSelectedCategory(response.data);
            // console.log('data',response.data.name);
        }).catch(() => {
            setPageLoading(false)
            redirect('careers');
        });
    }


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSearchValue(inputValue);
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [inputValue]);



    useEffect(() => {
        console.log('demo', searchValue, selectedCategory);
        if (searchValue != '' || selectedCategory != null) {
            ReloadTable();
        }
    }, [searchValue, selectedCategory])



    const ReloadTable = () => {
        setLoading(true);
        const categoryPass = selectedCategory!=null && selectedCategory != 'all' ? selectedCategory.code : null;
        dispatch(fetchCareers(searchValue, categoryPass)).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        });

    }

    // const onFinish = (data: any) => {

    //     const myinterval =  setTimeout(() => {
    //         setSearchValue();
    //     }, 1000);

    // };






    const SaveAndRemoveFromJobListProcess = (career_id: any, status: number) => {
        const requestData = {
            career_id: career_id,
            add: status
        }
        setLoading(true);
        dispatch(addRemoveJob(requestData)).then(() => {
            setLoading(false)
            ReloadTable();
        }).catch(() => {
            setLoading(false)
            ReloadTable();
        });
    }


    const columns = [
        {
            title: "Career Name",
            pinned: "left",
            width: "90%",
            key: 'name',
            field: "name",
            render: (item: any) => item.name,

        },
        {
            title: "",
            pinned: "left",
            width: "10%",
            key: 'name',
            field: "name",
            render: (item: any) => <>
                <Space align="center" size="large" key={item.id}>
                    <Tooltip placement="top" title={item.is_saved ? 'Remove from Saved List' : 'Add to Saved List'} overlayClassName='examTooltip' >
                        <Button className="common_button green_border_common_btn rounded" icon={item.is_saved ? <StarFilled /> : <StarOutlined />} onClick={() => SaveAndRemoveFromJobListProcess(item.id, item.is_saved ? 0 : 1)} ></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={'Details'} overlayClassName='examTooltip' >
                        <Button className="common_button green_border_common_btn rounded" icon={<ArrowRightOutlined />} onClick={() => setDetailsModalId(item.id)} ></Button>
                    </Tooltip>
                </Space>
            </>

        },

    ];





    return (
        !pageLoading ?
            <div>
                <section className='web-dashboard-section all-career-main'>
                    <div className='container'>
                        <div className='breadcrumb'>
                            <BreadcrumbComponent key={1} items={career_category != undefined && selectedCategory != undefined ? [...CareerSubBreadcrumb.path, { name: selectedCategory != 'all' ? selectedCategory.name : (String(selectedCategory).charAt(0).toUpperCase() + String(selectedCategory).slice(1)) }] : AllCareerBreadcrumb.path} />
                        </div>

                        <Row gutter={10}>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} xxl={{ span: 16 }} >
                                <Title level={3} style={{ color: '#1DAB3B' }}>Find Your Career in Offshore Wind</Title>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} xxl={{ span: 8 }} >
                                <Input addonBefore={<SearchOutlined />} placeholder="Search" style={{ margin: '3rem 0' }} onChange={(e) => { setInputValue(e.target.value); }} />
                            </Col>
                        </Row>
                        {career_category == undefined && !searchValue &&
                            <div className='totalAns'>
                                <Row gutter={[30, 35]} >
                                    {CarierCategoryList.length > 0 && CarierCategoryList.map((item: any, index: number) =>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }} xxl={{ span: 8 }} key={index}>
                                            <Link to={'/careers/' + btoa(item.id)}>

                                                <div
                                                    className='web-dashboard-counter' >
                                                    <div className='web-dashboard-counter-exam'>
                                                        <div className='web-dashboard-counter-exam-content'>
                                                            <FileDoneOutlined />
                                                            <p>
                                                                {item.full_name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='web-dashboard'>
                                                        <div className='rightArrow'>
                                                            <RightArrow />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>)}
                                    <Col xs={{ span: 24 }} md={{ span: 24 }} xxl={{ span: 24 }} >
                                        <Link to={'/careers/all'} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button className='common_button green_border_common_btn'>
                                                View List Of All Careers
                                            </Button>

                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {(career_category != undefined || searchValue) &&
                            <Row gutter={10} >
                                <Col xs={{ span: 24 }} md={{ span: 24 }} xxl={{ span: 24, offset: 0 }} >
                                    <Table
                                        loading={loading}
                                        dataSource={careerList || []}
                                        columns={columns}
                                        pagination={{ defaultPageSize: 20, position: ["bottomCenter"], }}
                                    />
                                </Col>
                            </Row>
                        }
                    </div>
                </section>
                {/*Modal for career Detail */}
                <CareerDetailsModal career_id={detailsModalId} onCancel={() => setDetailsModalId(null)} />


            </div> : <PageSpinner />
    );
}

export default Index;
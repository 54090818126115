const API_URL = {
  LOGIN: `/v1/login`,
  REGISTER: `/v1/register`,
  USER_REGISTER: (user_id: string): string => `/v1/user-register/${user_id}`,
  GET_USER: (user_id: string): string => `/v1/user-detail/${user_id}`,
  FORGOT_PASSWORD: `/v1/forgot-password`,
  ME: `/v1/me`,
  LOGOUT: `/v1/logout`,
  CHANGE_PASSWORD: `/v1/change-password`,
  UPDATE_PROFILE: `/v1/update-profile`,
  RESET_PASSWORD: (otp: string): string => `/v1/reset-password/${otp}`,
  VERIFY_PASSWORD: (otp: string): string => `/v1/verify-otp/${otp}`,
  REFRESH_TOKEN: `/v1/refresh-token`,
  COMMON: {
    SYNC: `/v1/sync`,
    PRIVILEGES: `/v1/privileges-list`,
    ROLES_LIST: (search: string): string =>
      `/v1/roles-list${search ? `?search=${search}` : ""}`,
  },
  UPDATEPROFILE: `/v1/update-profile`,
  RACE: `/v1/race-list`,
  VETERAN: `/v1/veteran-statuses-list`,
  CMS_DETAIL: (slug: string): string => `/v1/cms/detail?slug=${slug}`,
  pageSettings: (type: string): string => `v1/page-settings?type=${type}`,
  VR_TRAINING: `/v1/vr-training-location`,
  DELETE_ACCOUNT: `v1/delete-account`,
  DISABLED_WELCOME_MODAL: `v1/disabled-popup`,

  //exam section start
  EXAM_START: `/v1/assessment-exam/start`,
  INTEREST_ASSESSMENT: `/v1/interest-assessment`,
  SUBMIT_ANSWER: (id: any) => `/v1/assessment-exam/${id}/submit-answer`,
  TIER_ONE_SUBMIT_ANSWER: (id: any) => `/v1/assessment-exam/${id}/tier-one-questions`,
  FINISH_EXAM: (id: any) => `/v1/assessment-exam/${id}/complete`,
  EXAM_LIST: `/v1/assessment-exam`,
  SKILL_ASSESSMENTU: `/v1/skill-assessment`,
  VIEW_ASSEMENT: (id: any) => `/v1/assessment-exam/${id}/details`,
  MASTER_LOV_DATA: (param: any) => `/v1${param}`,
  EXAM_RESULT: `/v1/assessment-exam/result`,
  EXAM_RESULT_PDF:`/v1/assessment-exam/download-result-pdf`,
  VERIFY_TOKEN: (token: string): string => `/v1/verify-email/${token}`,
  //exam section end
  // CAREER_LIST: (search: string): string => `/v1/careers-list${search ? "?search="+search : "" }`,
  CAREER_LIST:  `/v1/careers-list`,
  CAREER_DETAILS: (career_id: number): string => `/v1/career-detail/${career_id}`,
  MY_JOB_LIST: `/v1/job-list`,
  JOB_ADD_REMOVE: `/v1/saved-job/store`,
  CAREER_CATEGORY_LIST: `/v1/career-categories`,
  CAREER_CATEGORY_DETAILS:(cc_id:any)=> `/v1/career-category-detail/${cc_id}`,
  GET_ACCESS_CODE: (access_code_id: string): string => `/v1/access-code-detail/${access_code_id}`,

  //exam section end
};

export default API_URL;

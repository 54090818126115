import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../../../store/app";
import api from "../../../../../store/api";
import API_URL from "../../../../../config/ApiUrl";
import { setUserOptions } from "../../../../../store/AuthSlice";

interface UserProfileState {
  userProfile: any;
  appLoading: boolean;
  race: any;
  veteran: any;
  vrTraining: any;
  startExam: any;
  interestAssment: any;
  skillAssessment: any;
  examListData: any;
  bothExamData: any;
  perPageSize: any;
  grid: any;
  profileProgress: any;
  masterDropDown: any;
  goSkillModal: boolean;
  examResultData: any;
  careerList: any
  savedJobList: any
}

const initialState = {
  userProfile: null,
  appLoading: true,
  race: [],
  veteran: [],
  vrTraining: [],
  startExam: null,
  interestAssment: null,
  skillAssessment: null,
  examListData: null,
  bothExamData: null,
  perPageSize: null,
  grid: null,
  profileProgress: null,
  masterDropDown: [],
  goSkillModal: false,
  examResultData: null,
  careerList: [],
  savedJobList: [],

} as UserProfileState;

const WebProfileSlice = createSlice({
  name: "WEBPROFILE",
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<any>) => {
      state.userProfile = action.payload;
    },
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setRace: (state, action: PayloadAction<any>) => {
      state.race = action.payload;
    },
    setveteran: (state, action: PayloadAction<any>) => {
      state.veteran = action.payload;
    },
    setVrTraining: (state, action: PayloadAction<any>) => {
      state.vrTraining = action.payload
    },
    setStartExam: (state, action: PayloadAction<any>) => {
      state.startExam = action.payload
    },
    setInterestAssessment: (state, action: PayloadAction<any>) => {
      state.interestAssment = action.payload
    },
    setSkillAssessment: (state, action: PayloadAction<any>) => {
      state.skillAssessment = action.payload
    },
    setExamListData: (state, action: PayloadAction<any>) => {
      state.examListData = action.payload
    },
    setBothExamData: (state, action: PayloadAction<any>) => {
      state.bothExamData = action.payload
    },
    setPerPageSize: (state, action: PayloadAction<any>) => {
      state.perPageSize = action.payload;
      if (state.grid) {
        state.grid.api.paginationSetPageSize(Number(state.perPageSize));
        state.grid.api.setCacheBlockSize(state.perPageSize);
      }
    },
    setGrid: (state, action: PayloadAction<any>) => {
      state.grid = action.payload
    },
    setMasterDropDown: (state, action: PayloadAction<any>) => {
      state.masterDropDown = action.payload
    },
    setGoSkillModal: (state, action: PayloadAction<any>) => {
      state.goSkillModal = action.payload
    },
    setExamResultData: (state, action: PayloadAction<any>) => {
      state.examResultData = action.payload
    },
    setCareerListData: (state, action: PayloadAction<any>) => {
      state.careerList = action.payload
    },
    setSavedJobList: (state, action: PayloadAction<any>) => {
      state.savedJobList = action.payload
    },


  },
});

export const { setUserProfile, setExamResultData, setAppLoading, setGoSkillModal, setRace, setveteran, setVrTraining, setStartExam, setGrid, setInterestAssessment, setPerPageSize, setBothExamData, setSkillAssessment, setExamListData, setMasterDropDown, setCareerListData, setSavedJobList } = WebProfileSlice.actions;

export const updateProfile =
  (data: string): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(setAppLoading(true));
        const response = await api.post(API_URL.UPDATEPROFILE, data)
        dispatch(setUserProfile(response.data));
        dispatch(setUserOptions());
        dispatch(setAppLoading(false));
        return Promise.resolve(response.data.rows);
      } catch (error: any) {
        dispatch(setAppLoading(false));
        dispatch(setUserProfile([]));
        return Promise.reject(error.data);
      }
    };

export const fetchRaceList = (): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.get(API_URL.RACE)
      dispatch(setRace(response.data.data))
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setRace([]));
      return Promise.reject(error.data);
    }
  }
export const fetchVeteranList = (): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.get(API_URL.VETERAN)
      dispatch(setveteran(response.data.data))
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setveteran([]));
      return Promise.reject(error.data);
    }
  }
export const fetchVrTraining = (): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.get(API_URL.VR_TRAINING)
      dispatch(setVrTraining(response.data.data))
      dispatch(setAppLoading(false))
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setveteran([]));
      return Promise.reject(error.data);
    }
  }
export const startExam = (data: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.post(API_URL.EXAM_START, data)
      dispatch(setStartExam(response.data.data))
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setStartExam(null));
      return Promise.reject(error.data);
    }
  }
export const fetchInterestAssessment = (): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.get(API_URL.INTEREST_ASSESSMENT)
      dispatch(setInterestAssessment(response.data.data))
      dispatch(setBothExamData(response.data.data))
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setInterestAssessment(null));
      dispatch(setBothExamData(null))
      return Promise.reject(error.data);
    }
  }
export const fetchSkillAssessment = (): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.get(API_URL.SKILL_ASSESSMENTU)
      dispatch(setSkillAssessment(response.data))
      dispatch(setBothExamData(response.data.data))
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setSkillAssessment(null));
      dispatch(setBothExamData(null))
      return Promise.reject(error.data);
    }
  }
export const submitAnswer = (id: any, data: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      const response = await api.post(API_URL.SUBMIT_ANSWER(id), data)
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      return Promise.reject(error.data);
    }
  }
export const tierOneSubmitAnswer = (id: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      const response = await api.post(API_URL.TIER_ONE_SUBMIT_ANSWER(id))
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      return Promise.reject(error.data);
    }
  }
export const finishExam = (id: any, data: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.post(API_URL.FINISH_EXAM(id))
      dispatch(setAppLoading(false));
      dispatch(setUserOptions())
      if (data?.assessment_type === "2") {
        dispatch(setGoSkillModal(true))
      }

      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      return Promise.reject(error.data);
    }
  }
export const getExamList = (data: any): AppThunk<any> =>

  async (dispatch) => {
    const payload = {
      assessment_type: data
    }
    try {
      dispatch(setAppLoading(true));
      const response = await api.post(API_URL.EXAM_LIST, payload)
      dispatch(setAppLoading(false));
      dispatch(setExamListData(response.data))
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      return Promise.reject(error.data);
    }
  }
export const ViewIntersetExam = (id: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.post(API_URL.VIEW_ASSEMENT(id))
      dispatch(setBothExamData(response.data.data))
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setBothExamData(null))
      return Promise.reject(error.data);
    }
  }
export const disabledWelcomeModal = (): AppThunk<any> =>
  async () => {
    try {
      const response = await api.post(API_URL.DISABLED_WELCOME_MODAL)
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      return Promise.reject(error.data);
    }
  }
export const masterLov = (param: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      const response = await api.get(API_URL.MASTER_LOV_DATA(param))
      dispatch(setMasterDropDown(response.data.data))
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setMasterDropDown([]))
      return Promise.reject(error.data);
    }
  }
export const examResult = (): AppThunk<any> =>
  async (dispatch) => {
    try {
      const response = await api.post(API_URL.EXAM_RESULT)
      dispatch(setExamResultData(response.data.data))
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setMasterDropDown([]))
      return Promise.reject(error.data);
    }
  }
  export const downloadReportPdf = (): AppThunk<any> =>
  async () => {
    try {
      const response = await api.post(API_URL.EXAM_RESULT_PDF)
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      return Promise.reject(error.data);
    }
  }


export const fetchCareers = (search: any = "", career_category: any = null): AppThunk<any> =>
  async (dispatch) => {
    try {
      const requestData = {
        category_id: career_category,
        search: search
      }
      dispatch(setAppLoading(true));
      // const response = await api.post(API_URL.CAREER_LIST(search))
      const response = await api.post(API_URL.CAREER_LIST, requestData)
      dispatch(setCareerListData(response.data.rows))
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setCareerListData([]))
      return Promise.reject(error.data);
    }
  }


export const fetchCareerDetails = (career_id: any): AppThunk<any> =>
  async () => {
    try {
      const response = await api.get(API_URL.CAREER_DETAILS(career_id))
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      return Promise.reject(error.data);
    }
  }



export const fetchSavedJobs = (): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.get(API_URL.MY_JOB_LIST)
      dispatch(setSavedJobList(response.data.rows))
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setSavedJobList(null));
      return Promise.reject(error.data);
    }
  }


export const fetchCareerCategorys = (): AppThunk<any> =>
  async () => {
    try {
      const response = await api.get(API_URL.CAREER_CATEGORY_LIST)
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      return Promise.reject(error.data);
    }
  }

export const fetchCareerCategoryDetails = (cc_id: any): AppThunk<any> =>
  async () => {
    try {
      const response = await api.get(API_URL.CAREER_CATEGORY_DETAILS(cc_id))
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      return Promise.reject(error.data);
    }
  }

export const addRemoveJob = (requestData: any): AppThunk<any> =>
  async () => {
    try {
      const response = await api.post(API_URL.JOB_ADD_REMOVE, requestData)
      return Promise.resolve(response.data);
    }
    catch (error: any) {
      return Promise.reject(error.data);
    }
  }
export default WebProfileSlice.reducer;

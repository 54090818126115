import { Button, Col, Form, Progress, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { ReactComponent as CarbonUser } from "../../../../../assets/webimage/CarbonUser.svg";
import { rules } from "./Rules";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import "../../Style/userProfile.less";
import {
  fetchRaceList,
  fetchVeteranList,
  setRace,
  setveteran,
  updateProfile,
} from "../Utils/slice";
import { assignErrorToInput } from "../../../../../store/api";
import { CONSTANT } from "../../../../../config/Constant";
import BreadcrumbComponent from "../../../CommonComponent/BreadcrumbComponent";
import { UserProfileBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import dayjs from "dayjs";
import { mobilePattern } from "../../../../../config/Global";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../../../../components/Modals/CommonModal";
import { deleteAccount } from "../../../../../store/AuthSlice";

/**
 * Renders form component to update the User Profile.
 *
 * @return {JSX.Element} The rendered form component.
 */
const UserProfile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const { userDetail, mapScriptLoaded } = useSelector(
    (state: RootState) => state.AUTH
  );
  const raceData = useSelector((state: RootState) => state.WEBPROFILE.race);
  const veteranData = useSelector(
    (state: RootState) => state.WEBPROFILE.veteran
  );
  const [latLng, setLatLng] = useState<{ lat: number; lng: number }>();
  const [genderCheck, setGenderCheck] = useState("0");
  const [disabilitiesCheck, setDisabilitiesCheck] = useState("0");
  const [veteranStatusCheck, setVeteranStatusCheck] = useState("0");
  const [houseHoldCheck, setHouseHoldCheck] = useState("0");
  const [EthnicityCheck, setEthnicityCheck] = useState("0");
  const profileProgress = useSelector(
    (state: RootState) => state.AUTH.profileProgress
  );
  const [disabilityStatus, setDisabilityStatus] = useState<any>();
  const [houseHold, setHouseHold] = useState<any>();

  const [save, setSave] = useState<boolean>(false);
  const [deleteAccoutnModal, setDeleteAccountModal] = useState<boolean>(false);
  const initialStoreData: any = useSelector(
    (state: RootState) => state.AUTH.initialData
  );

  // code for fetching state detail from zip code : start
  const [result, setResult] = useState<any>();
  const [pinCode, setPinCode] = useState<any>();
  const [stateDetail, setStateDetail] = useState<any>([]);
  const [selectOk, setSelectOk] = useState<number>(0);
  const [list, setList] = useState<any>();
  const [pinCodeFinal, state] = stateDetail;
  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] =
    useState(false);

  useEffect(() => {
    const onlyContainsNumbers = (pinCode: any) => /^\d+$/.test(pinCode);

    if (onlyContainsNumbers(pinCode)) {
      setIsPlacePredictionsLoading(true);
      const geocoder: google.maps.Geocoder = new google.maps.Geocoder();

      const geoCode: any = (request: google.maps.GeocoderRequest): void => {
        geocoder
          .geocode(request)
          .then((result: any) => {
            setResult(result);
          })
          .catch(() => {
            console.log("GeoCode not found");
          });
      };
      geoCode({ address: pinCode });
    }
    // if (pinCode?.length === 0) { setList([]), setResult([]) }
  }, [pinCode]);

  useEffect(() => {
    if (Array.isArray(result?.results)) {
      const mappedList = result?.results.map((data: any, index: number) => ({
        id: index + 1,
        name: data.formatted_address,
      }));
      setList(mappedList);
      setIsPlacePredictionsLoading(false);
    }
  }, [result]);
  useEffect(() => {
    if (selectOk !== 0 && result.results) {
      const details = result?.results[selectOk - 1]?.address_components;
      setStateDetail([
        details?.[0]?.long_name,
        details?.[details.length - 2]?.long_name,
      ]);
      form.setFieldsValue({ location: details?.[0]?.long_name });
    } else {
      setStateDetail([]);
    }
  }, [selectOk]);

  // code for fetching state detail from zip code : end

  useEffect(() => {
    const initialData: any = sessionStorage?.getItem("initialData");
    if (initialData) {
      const { disability_status } = JSON.parse(initialData);
      setDisabilityStatus(disability_status);
    }
    if (initialData) {
      const { annual_income } = JSON.parse(initialData);
      setHouseHold(annual_income);
    } else {
      setHouseHold(initialStoreData.annual_income);
    }
    if (userDetail?.location) {
      setPinCode(userDetail?.location);
    }

    if (userDetail?.race_id) {
      dispatch(setRace([userDetail.race]));
      form.setFieldsValue({
        race_id: userDetail?.race_id,
      });
    }
    if (userDetail?.veteran_status_id) {
      dispatch(setveteran([userDetail?.veteran]));
      form.setFieldsValue({
        veteran_status: userDetail?.veteran_status_id,
      });
    }

    form.setFieldsValue({
      ...userDetail,
      decline_to_answer_disabilities: [
        userDetail?.decline_to_answer_disabilities,
      ],
      decline_to_answer_gender: [userDetail?.decline_to_answer_gender],
      decline_to_answer_race_id: [userDetail?.decline_to_answer_race_id],
      decline_to_answer_veteran_status: [
        userDetail?.decline_to_answer_veteran_status,
      ],
      decline_to_answer_household_income: [
        userDetail?.decline_to_answer_household_income,
      ],
      dob: userDetail?.dob ? dayjs(userDetail?.dob) : "",
    });
    if (userDetail?.disabilities) {
      form.setFieldValue("disabilities", userDetail?.disabilities?.id);
    }
    if (userDetail?.house_hold_income) {
      form.setFieldValue(
        "house_hold_income",
        userDetail?.house_hold_income?.id
      );
    }

    setLatLng({
      lat: Number(userDetail?.latitude),
      lng: Number(userDetail?.longitude),
    });
    if (userDetail?.mobile_no) {
      form.setFieldsValue({ mobile_no: mobilePattern(userDetail?.mobile_no) });
    }
    setGenderCheck(userDetail?.decline_to_answer_gender?.toString());
    setDisabilitiesCheck(
      userDetail?.decline_to_answer_disabilities?.toString()
    );
    setVeteranStatusCheck(
      userDetail?.decline_to_answer_veteran_status?.toString()
    );
    setHouseHoldCheck(
      userDetail?.decline_to_answer_household_income?.toString()
    );
    setEthnicityCheck(userDetail?.decline_to_answer_race_id?.toString());
  }, [userDetail, setRace, form]);

  /**
    * Updates the user profile with the given data and navigates to the dashboard page 
    * on success and throws an error on failure.
    *
    * @param {any} data - The data to update the user's profile with.
    * @return {void}
    */
  const userProfileUpdate = (data: any) => {
    setSave(true);
    // data.zip
    (data.location = pinCodeFinal
      ? Number(pinCodeFinal)
      : userDetail?.location),
      (data.state = state ? state : userDetail?.state);
    data.decline_to_answer_disabilities =
      data.decline_to_answer_disabilities[0];
    data.decline_to_answer_gender = data.decline_to_answer_gender[0];
    data.decline_to_answer_race_id = data.decline_to_answer_race_id[0];
    data.decline_to_answer_veteran_status =
      data.decline_to_answer_veteran_status[0];
    data.decline_to_answer_household_income =
      data.decline_to_answer_household_income[0];
    data.dob = dayjs(data.dob).format(CONSTANT.POST_DATE_FORMAT);
    data.latitude = latLng?.lat;
    data.longitude = latLng?.lng;
    if (!data.decline_to_answer_disabilities) {
      data.decline_to_answer_disabilities = 0;
    }
    if (!data.decline_to_answer_gender) {
      data.decline_to_answer_gender = 0;
    }
    if (!data.decline_to_answer_race_id) {
      data.decline_to_answer_race_id = 0;
    }
    if (!data.decline_to_answer_veteran_status) {
      data.decline_to_answer_veteran_status = 0;
    }
    if (!data.decline_to_answer_household_income) {
      data.decline_to_answer_household_income = 0;
    }

    dispatch(updateProfile(data))
      .then(() => {
        setSave(false);
        navigate("/dashboard");
      })
      .catch((error: any) => {
        setSave(false);
        assignErrorToInput(form, error?.STATUS);
      });
  };

/** 
  * Function to open, close delete account modal and dispatch delete account action 
  * on confirmation 
  */
  const openDeleteAccountModal = () => {
    setDeleteAccountModal(true);
  };
  const closeDeleteAccountModal = () => {
    setDeleteAccountModal(false);
  };
  const deleteAccountBtn = () => {
    dispatch(deleteAccount()).catch((e: any) => e);
  };

  
  const getRaceList = () => {
    dispatch(fetchRaceList()).catch((error: any) =>
      assignErrorToInput(form, error?.STATUS)
    );
  };
  const getVeteranList = () => {
    dispatch(fetchVeteranList()).catch((error: any) =>
      assignErrorToInput(form, error?.STATUS)
    );
  };

  // For progress bar for the user profile
  const progressContent = (title: any) => {
    return (
      <>
        <div className="progress_content">
          <p className="progress_title">{title} :</p>
          <Progress percent={profileProgress} />
        </div>
        {/* <Divider /> */}
      </>
    );
  };

  // Set the minimum Birth date to 13 years ago 
  const minDate = dayjs().subtract(13, "years");
  const disableDate = (current: any) => {
    return current && current > minDate;
  };

  useEffect(() => {
    //
  }, [latLng, mapScriptLoaded]);

  // For form validation on change
  const handleChange = () => {
    form.validateFields();
  };

  return (
    <div className="windBgImg">
      <div className="container">
        <div className="breadcrumb">
          <BreadcrumbComponent key={1} items={UserProfileBreadcrumb.path} />
        </div>

        <div className="profileLayout">
          <div className="userProfileContent userContent">
            <div className="userProfileImg">
              <CarbonUser />
            </div>
            <div className="progress">
              {progressContent("Profile Complete")}
            </div>
          </div>

          <FormBox
            layout="vertical"
            autoComplete="off"
            form={form}
            id="userProfileUpdate"
            onFinish={userProfileUpdate}
            onChange={handleChange}
            className="userProfile commanDrawer"
          >
            <Row gutter={30}>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <InputBox.Text
                  name="name"
                  label="First Name"
                  placeholder="First Name"
                  rules={rules.name()}
                />
              </Col>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <InputBox.Text
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  rules={rules.last_name()}
                />
              </Col>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <InputBox.Text
                  name="email"
                  label="Email ID"
                  placeholder="Enter Email"
                  disabled
                  rules={rules.email()}
                />
              </Col>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <InputBox.Text
                  name="mobile_no"
                  placeholder="000-000-0000"
                  label="Mobile No"
                  maxLength={10}
                  onChange={(e: any) => {
                    if (e.target.value.length > 0) {
                      form.setFieldsValue({
                        mobile_no: mobilePattern(e.target.value),
                      });
                    }
                  }}
                />
              </Col>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <InputBox.DatePicker
                  required
                  label="Date of birth"
                  name="dob"
                  allowClear={false}
                  rules={rules.dob()}
                  disabledDate={disableDate}
                  defaultPickerValue={minDate}
                />
              </Col>
              {userDetail?.company && userDetail?.company?.id!=undefined &&
                <Col sm={12} xs={24} md={8} xxl={8}>
                  <InputBox.Text
                    label="Customer"
                    placeholder="Customer"
                    value={userDetail?.company?.company_name}
                    disabled={true}
                  />
                </Col>
              }
              <Col sm={12} xs={24} md={8} xxl={8}>
                <div className="d-flex justify-space-between align-items-center lableWithCheckBox">
                  <label className="profileLable">Gender</label>
                  <InputBox.Checkbox
                    noStyle
                    name="decline_to_answer_gender"
                    options={{
                      list: [
                        {
                          id: 1,
                          name: "Decline to answer",
                        },
                      ],
                      valueKey: "id",
                      textKey: "name",
                    }}
                    onChange={(e: any) => {
                      setGenderCheck(
                        e[0] === "0" || e[0] === undefined ? "0" : "1"
                      );
                      if (Number(genderCheck) === 0) {
                        form.setFieldsValue({ gender: null });
                      }
                    }}
                  />
                </div>
                <InputBox.Select
                  name="gender"
                  placeholder="Gender"
                  allowClear={true}
                  rules={genderCheck === "1" ? [] : rules.gender()}
                  disabled={genderCheck === "1" ? true : false}
                  options={{
                    list: CONSTANT.GENDERLIST,
                    valueKey: "id",
                    textKey: "name",
                  }}
                />
              </Col>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <div className="d-flex justify-space-between align-items-center lableWithCheckBox">
                  <label className="profileLable">Ethnicity</label>
                  <InputBox.Checkbox
                    noStyle
                    name="decline_to_answer_race_id"
                    options={{
                      list: [
                        {
                          id: 1,
                          name: "Decline to answer",
                        },
                      ],
                      valueKey: "id",
                      textKey: "name",
                    }}
                    onChange={(e: any) => {
                      setEthnicityCheck(
                        e[0] === "0" || e[0] === undefined ? "0" : "1"
                      );
                      if (Number(EthnicityCheck) === 0) {
                        form.setFieldsValue({ race_id: null });
                      }
                    }}
                  />
                </div>
                <InputBox.Select
                  placeholder="Ethnicity"
                  onFocus={getRaceList}
                  name="race_id"
                  allowClear={true}
                  rules={EthnicityCheck === "1" ? [] : rules.race()}
                  disabled={EthnicityCheck === "1" ? true : false}
                  options={{
                    list: raceData,
                    valueKey: "id",
                    textKey: "name",
                  }}
                />
              </Col>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <div className="d-flex justify-space-between align-items-center lableWithCheckBox">
                  <label className="profileLable">Disability Status</label>
                  <InputBox.Checkbox
                    noStyle
                    name="decline_to_answer_disabilities"
                    options={{
                      list: [
                        {
                          id: 1,
                          name: "Decline to answer",
                        },
                      ],
                      valueKey: "id",
                      textKey: "name",
                    }}
                    onChange={(e: any) => {
                      setDisabilitiesCheck(
                        e[0] === "0" || e[0] === undefined ? "0" : "1"
                      );
                      if (Number(disabilitiesCheck) === 0) {
                        form.setFieldsValue({ disabilities: null });
                      }
                    }}
                  />
                </div>
                <InputBox.Select
                  placeholder="Disabilities"
                  name="disabilities"
                  rules={disabilitiesCheck === "1" ? [] : rules.disabilities()}
                  allowClear={true}
                  disabled={disabilitiesCheck === "1" ? true : false}
                  options={{
                    list: disabilityStatus,
                    valueKey: "id",
                    textKey: "name",
                  }}
                />
              </Col>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <div className="d-flex justify-space-between align-items-center lableWithCheckBox">
                  <label className="profileLable">Military Status</label>
                  <InputBox.Checkbox
                    noStyle
                    name="decline_to_answer_veteran_status"
                    options={{
                      list: [
                        {
                          id: 1,
                          name: "Decline to answer",
                        },
                      ],
                      valueKey: "id",
                      textKey: "name",
                    }}
                    onChange={(e: any) => {
                      setVeteranStatusCheck(
                        e[0] === "0" || e[0] === undefined ? "0" : "1"
                      );
                      if (Number(veteranStatusCheck) === 0) {
                        form.setFieldsValue({ veteran_status: null });
                      }
                    }}
                  />
                </div>
                <InputBox.Select
                  name="veteran_status"
                  placeholder="Military Status"
                  allowClear={true}
                  rules={
                    veteranStatusCheck === "1" ? [] : rules.veteran_status()
                  }
                  disabled={veteranStatusCheck === "1" ? true : false}
                  onFocus={getVeteranList}
                  options={{
                    list: veteranData,
                    valueKey: "id",
                    textKey: "name",
                  }}
                />
              </Col>
              <InputBox.Text hidden name="location" />
              <Col sm={12} xs={24} md={8} xxl={8}>
                <InputBox.Select
                  allowClear
                  name="location"
                  rules={rules.zipCode()}
                  label="Location (Zip Code)"
                  placeholder="Search Location (Zip Code)"
                  onKeyUp={() => {
                    setTimeout(() => {
                      setIsPlacePredictionsLoading(false);
                    }, 2000);
                  }}
                  showSearch
                  onSearch={(value: any) => {
                    setPinCode(value);
                  }}
                  notFoundContent={
                    isPlacePredictionsLoading ? (
                      <Spin size="default" />
                    ) : (
                      "No Place Found."
                    )
                  }
                  optionFilterProp="children"
                  onSelect={(e) => {
                    setSelectOk(e);
                  }}
                  onClear={() => (
                    setList([]), setStateDetail([]), setSelectOk(0)
                  )}
                  options={{
                    list: list,
                  }}
                />
              </Col>
              <Col sm={12} xs={24} md={8} xxl={8}>
                <div className="d-flex justify-space-between align-items-center lableWithCheckBox">
                  <label className="profileLable">Household Income</label>
                  <InputBox.Checkbox
                    noStyle
                    name="decline_to_answer_household_income"
                    options={{
                      list: [
                        {
                          id: 1,
                          name: "Decline to answer",
                        },
                      ],
                      valueKey: "id",
                      textKey: "name",
                    }}
                    onChange={(e: any) => {
                      setHouseHoldCheck(
                        e[0] === "0" || e[0] === undefined ? "0" : "1"
                      );
                      if (Number(houseHoldCheck) === 0) {
                        form.setFieldsValue({ house_hold_income: null });
                      }
                    }}
                  />
                </div>
                <InputBox.Select
                  name="house_hold_income"
                  placeholder="House Income"
                  allowClear={true}
                  rules={houseHoldCheck === "1" ? [] : rules.veteran_status()}
                  disabled={houseHoldCheck === "1" ? true : false}
                  options={{
                    list: houseHold,
                    valueKey: "id",
                    textKey: "name",
                  }}
                />
              </Col>
            </Row>
            <div className="profileBtn">
              <Button
                form="userProfileUpdate"
                className="common_Button green_border_common_btn"
                htmlType="submit"
                type="primary"
                loading={save}
              >
                Save
              </Button>
              <Button
                form="userProfileUpdate"
                className="common_Button green_border_common_btn"
                onClick={() => navigate("/dashboard")}
              >
                Cancel
              </Button>
              <Button
                form="userProfileUpdate"
                className="red_border_common_btn "
                htmlType="button"
                onClick={openDeleteAccountModal}
              >
                Delete Account
              </Button>
            </div>
          </FormBox>
        </div>
      </div>
      <CommonModal
        title={"Delete Account?"}
        open={deleteAccoutnModal}
        onCancel={closeDeleteAccountModal}
        className="logOutModal"
        footer={[
          <Button
            key="1"
            htmlType="button"
            className=" red_border_common_btn"
            onClick={() => deleteAccountBtn()}
          >
            Confirm
          </Button>,
          <Button
            key="2"
            htmlType="button"
            type="primary"
            className="common_button green_border_common_btn"
            onClick={closeDeleteAccountModal}
          >
            Cancel
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete Account?</p>
      </CommonModal>
    </div>
  );
};

export default UserProfile;

import React, { useEffect, useRef, useState } from "react";
import "./Map.less";
import { useSelector } from "react-redux";
import { RootState } from "../../store/app";
import greenImg from "../../assets/webimage/Green-Location-icon.png"
// import redImg from "../../assets/webimage/Red-Location-icon.png"
import blueImg from "../../assets/webimage/Blue-Location-icon.png"
import ReactDOMServer from 'react-dom/server';
import SVGIcon from "../../utils/SVGIcon";

interface IMarkerMap {
  zoomLevel?: number;
  latLong: { latitude: any; longitude: any; location: any }[];
  gestureHandling?: boolean;
}

// const marker_icon = "https://maps.google.com/mapfiles/marker.png";
// const green = "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
// const marker_icon = redImg
const marker_icon = blueImg

const green = greenImg
type GoogleMap = google.maps.Map;

/**
 * Renders a multiple marker map component use for showing career and training locations .
 *
 * @param {IMarkerMap} props - The props object containing the following properties:
 *   - zoomLevel: The initial zoom level of the map (default: 3).
 *   - latLong: An array of objects containing latitude, longitude, and location information for each marker.
 *   - gestureHandling: A boolean indicating whether gesture handling is enabled (default: false).
 * @return {ReactElement} The rendered multiple marker map component.
 */
const MultiMarkerMap: React.FC<IMarkerMap> = ({
  zoomLevel = 3,
  latLong,
  gestureHandling,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<GoogleMap | null>(null);
  map
  const userLocation = useSelector((state: RootState) => state.AUTH.userDetail)

  const defaultLatitude = latLong[0]?.latitude;
  const defaultLongitude = latLong[0]?.longitude;

  // Initialize Map
  useEffect(() => {
    const mapOptions = {
      zoom: zoomLevel,
      center: new google.maps.LatLng(defaultLatitude ? defaultLatitude : (userLocation?.latitude) ? (userLocation.latitude) : 39.8097343, defaultLongitude ? defaultLongitude : (userLocation?.longitude) ? (userLocation.longitude) : -98.5556199),
    };

    const map: any = new google.maps.Map(ref.current as HTMLElement, mapOptions);

    setMap(map);
    if (gestureHandling) {
      map.setOptions({ gestureHandling: "none", keyboardShortcuts: false });
    }
    const bounds = new google.maps.LatLngBounds();
    latLong.forEach((coords: any) => {
      bounds.extend(new google.maps.LatLng(coords.latitude, coords.longitude));
    });

    // Set center and zoom based on bounds
    if (latLong.length > 0 && map) {
      map.fitBounds(bounds);
      // Optionally, set a max zoom level
      const maxZoom = 15;
      if (map.getZoom() > maxZoom) {
        map.setZoom(maxZoom);
      }
    }
    latLong.forEach((coords: any) => {
      const newMarker = new google.maps.Marker({
        position: new google.maps.LatLng(coords.latitude, coords.longitude),
        map: map,
        draggable: false,
        icon: coords.type === 1 ? marker_icon : green,
      });


    /**  
      * The code for pop up info window for showing location description : start
      */

      //Add event listener for marker click or drag events here if needed
      newMarker.addListener("click", () => {
        showInfoWindow(map, newMarker, coords);
      });
    });
    let infoWindow: any = null;

    const showInfoWindow = (map: any, marker: any, coords: any) => {
      
      const contentString  = ReactDOMServer.renderToString(
        <div className="locationDescription">
          <div id={coords.type === 1 ? "redHeading" : "greenHeading"} className={coords.type === 1 ? "redHeading" : "greenHeading" } title={coords.name}>
            <h1><b>{coords.name}</b></h1>
            <div className="locationIcon"  id="locationIcon" title="Close">
              <SVGIcon icon="close" color="#ffffff"/>
            </div> 
          </div>
          <div id="bodyContent" className="bodyContent">
            <h4>{coords.location}</h4>
            {coords.description ? <p className="bodyContentDescription">{coords.description}</p> : null}
          </div>
        </div>
      );

      if (infoWindow) {
        infoWindow.close();
      }

      infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      infoWindow.open(map, marker);

      google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
        document.getElementById("locationIcon")?.addEventListener('click', () => {
          if (infoWindow) {
            infoWindow.close();
          }
        });
      });
    };
    /**
     * The code for pop up info window for showing location description : end
     */

  }, [latLong, gestureHandling]);

  return (
    <div className="map-container vr-map">
      <div ref={ref} className="map-container__map" id="map-container__map"></div>
    </div>
  );
};

export default MultiMarkerMap;

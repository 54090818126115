import React, { useEffect, useState } from 'react'
import BreadcrumbComponent from '../../../CommonComponent/BreadcrumbComponent'
import { ResultBreadcrumb } from '../../../../../config/BreadcrumbConfig'
import { Col,  Progress, Row, Spin, Tooltip } from 'antd'
import { DownloadOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ReactComponent as GraphImage } from "../../../../../assets/images/icon/Figpie.svg";
import { disabledWelcomeModal, downloadReportPdf, examResult } from '../Utils/slice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../store/app'
import PageSpinner from '../../../../../components/PageSpinner/PageSpinner'
// import { WebConstant } from '../../../webConstant/WebConstant';
import { b64toBlob } from '../../../../../config/Global'
import CareerDetailsModal from '../AllCareers/components/CareerDetailsModal'


const Result = () => {
    const dispatch: AppDispatch = useDispatch();
    const [resultData, setResultData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const { userDetail } = useSelector((state: RootState) => state.AUTH);
    const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
    const [loadingQuestionPdf, setLoadingQuestionPdf] = useState<boolean>(false);
    const [detailsModalId, setDetailsModalId] = useState<any>(null);


    useEffect(() => {
        dispatch(examResult()).then((data: any) => {
            setResultData(data.data)
            setLoading(false)
        }).catch((e: any) => {
            e
            setLoading(false)
        })
        localStorage.setItem("feedback", "true")
        // this api call for not get welcome modal after user gone result page
        if (userDetail.popup_disabled !== 1) {
            dispatch(disabledWelcomeModal()).catch((e: any) => e)
        }
    }, [])


    // Downloads career compass report as a PDF file.
    const downloadReport = () => {
        setLoadingPdf(true)
        dispatch(downloadReportPdf()).then((data: any) => {
            const file = b64toBlob(data.data, "application/pdf", 512);
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            setLoadingPdf(false)
        }).catch((e: any) => {
            e
            setLoadingPdf(false)
        }
        )
    }

    // Download career compass info pdf file.
    const handleFetchPdf = () => {
        setLoadingQuestionPdf(true);
        try {
          window.open("/assets/Career.pdf", '_blank');
          setLoadingQuestionPdf(false);
        } catch (error) {
          setLoadingQuestionPdf(false);
          console.error('Error opening PDF:', error);
        }
      };

    return !loading ? (
        <div className="container">
            <div className="breadcrumb">
                <BreadcrumbComponent
                    key={1}
                    items={ResultBreadcrumb.path}
                />
            </div>
            <div className="interesQnaSection reportSection">
                <div className='totalAns'>
                    <div className="totalAnsMain">
                        <div className='allAns'>
                            <div className='currentAns'>Here are your top career matches:</div>
                            <div className='downloadAndInfo'>
                                <div className='downloadReport' onClick={downloadReport}>
                                    <Spin style={{ color: 'white' }} spinning={loadingPdf} indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
                                    <DownloadOutlined /> <span className='downloadTitle'> Download your report</span>
                                </div>
                                <div className='downloadReportTooltip' onClick={downloadReport}>
                                    <Tooltip title="Download your report" overlayClassName='examTooltip'>
                                        <Spin style={{ color: 'white' }}  spinning={loadingPdf} indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
                                         {!loadingPdf && <DownloadOutlined />}
                                    </Tooltip>
                                </div>
                                <div className='reportInfo' onClick={handleFetchPdf} >
                                    <Tooltip title="Need help understanding your Career Compass? Click Here!" trigger={'hover'} overlayClassName='examTooltip'>
                                        {loadingQuestionPdf ? <Spin spinning={loadingQuestionPdf} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}  /> : <QuestionCircleOutlined />}                                        

                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ansListRadiobtn'>
                        <Row gutter={[5, 5]} align={'stretch'}>
                            <Col sm={24} md={24} xl={8} >
                                <div className='graphImage'>
                                <GraphImage/>
                                </div>
                                <h5 className='graphInst'>Click on <span>See Details</span> under each career to learn more about your personal career matches in offshore wind!</h5>
                            </Col>
                            <Col sm={24} md={24} xl={16}>
                                <div className='reportContentWrap'>
                                    {
                                        resultData &&
                                        resultData?.result?.map((item: any, index: number) => {
                                            return (
                                                <div className='reportContent' key={index}>
                                                    <div className='categoryTitle'>
                                                        <h3>{item.career_categories}</h3>
                                                    </div>
                                                    <div className='reportName'>
                                                        <p>{item.careers}</p>
                                                    </div>
                                                    <Tooltip title={`You scored ${Number(item?.interest_assessment_score)} for Interest Discovery`} overlayClassName='examTooltip' trigger={'hover'}>
                                                        <Progress percent={item?.interest_assessment_score} size={[300, 15]} strokeColor="#1DAB3B" showInfo={false} />
                                                    </Tooltip>
                                                    <Tooltip title={`You scored ${Number(item?.skills_assessment_score)} for Skill Portrait`} overlayClassName='examTooltip' trigger={'hover'}>
                                                        <Progress percent={Number(item?.skills_assessment_score)} size={[300, 15]} strokeColor="#009CD1" showInfo={false} />
                                                    </Tooltip>
                                                    <div className='seeMore'>
                                                        <span onClick={() => setDetailsModalId(item.careers_detail.id)}>See Details</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='colorInformation'>
                                    <div className='colorInterest colorBox'>
                                        <span className='interestColorBox '></span>
                                        Interest Discovery

                                    </div>
                                    <div className='colorSkill colorBox'>
                                        <span className='skillColorBox '></span>
                                        Skill Portrait
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/*Modal for career Detail */}
            <CareerDetailsModal career_id={detailsModalId} onCancel={() => setDetailsModalId(null)} />

            
        </div>
    ) : <PageSpinner />
}

export default Result
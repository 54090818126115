import { Col,  Row,  Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { AppDispatch} from '../../../../../../store/app';
import { useDispatch } from 'react-redux';
import { fetchCareerDetails} from '../../Utils/slice';
import CommonModal from '../../../../../../components/Modals/CommonModal';
import { WebConstant } from '../../../../webConstant/WebConstant';



/**
 * Renders component for Interest Discovery Exam.
 *
 * @return {JSX.Element} The rendered component.
 */
const CareerDetailsModal = ({ career_id ,onCancel}: any) => {

    const [detailsModalData, setDetailsModalData] = useState<any>();
    const [detailsModalTitle, setDetailsModalTitle] = useState<any>();
    const [detailsModal, setDetailsModal] = useState<any>(false)
    const dispatch: AppDispatch = useDispatch();

    const firstColumnDataBoolean = Boolean(detailsModalData?.description || detailsModalData?.knowledge_skills || detailsModalData?.video_url || detailsModalData?.file);

    // true if any children component has value in requirement section in career details. 
    const requirementDataBoolean = Boolean(detailsModalData?.education_training || detailsModalData?.experience || detailsModalData?.credentialing);

    // true if any children component has value in second column in career details. 
    const secondColumnBoolean = Boolean(requirementDataBoolean || detailsModalData?.position_reports_to || detailsModalData?.career_path_moves_from_this_role || detailsModalData?.career_category_text);

    useEffect(() => {
        if (career_id) {
            showCareerDetails(career_id);
        }
        else {
            setDetailsModal(false)
            setTimeout(() => {
                setDetailsModalData(null)
                setDetailsModalTitle(null)
            }, 1000);
        }
    }, [career_id])



    const showCareerDetails = (career_id: any) => {
        // setLoading(true);
        dispatch(fetchCareerDetails(career_id)).then((data: any) => {
            console.log(data);

            setDetailsModal(true)
            setDetailsModalData(data.data)
            setDetailsModalTitle(data.data.name)
            // setLoading(false)
        }).catch(() => {
            // setLoading(false)
        })
    }

    return (<><CommonModal
        title={detailsModalTitle}
        open={detailsModal}
        onCancel={onCancel}
        className='analystModal'
        width={firstColumnDataBoolean && secondColumnBoolean ? 1300 : 650}
    >
        <Row gutter={[30, 30]}>
            {firstColumnDataBoolean && (
                <Col sm={24} md={secondColumnBoolean ? 12 : 24}  >
                    <div className='firstColumn' >
                        {detailsModalData?.description && (
                            <>
                                <div className='analystTitle'>
                                    <h4>DESCRIPTION</h4>
                                </div>
                                <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: detailsModalData.description }}></div>
                            </>
                        )}
                        {detailsModalData?.knowledge_skills && (
                            <>
                                <div className='analystTitle'>
                                    <h4>KNOWLEDGE/SKILLS</h4>
                                </div>
                                <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: detailsModalData.knowledge_skills }}></div>
                            </>
                        )}
                        {detailsModalData?.video_url &&
                            <div className='careerResultVideo'>
                                <iframe
                                    width="100%"
                                    height="350px"
                                    src={`https://www.youtube.com/embed/${detailsModalData?.video_url?.split('v=')[1]}?autoplay=1&mute=1`}
                                    title="YouTube Video"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </div>

                        }
                        {detailsModalData?.file &&
                            <div className='careerResultImage'>
                                <Image
                                    width={"100%"}
                                    src={WebConstant?.IMAGE_URL + detailsModalData?.file}
                                />
                            </div>
                        }

                    </div>
                </Col>
            )}
            {secondColumnBoolean && (
                <Col sm={24} md={firstColumnDataBoolean ? 12 : 24}  >
                    {requirementDataBoolean && (
                        <div className='boxContent'>
                            <div className='boxTitle'>
                                <h4>REQUIREMENTS</h4>
                            </div>
                            <div className='boxInnerContent'>
                                {detailsModalData?.education_training && (
                                    <div className='requirementSubHeader'>
                                        <h4>Education/Training</h4>
                                        <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: detailsModalData.education_training }}></div>
                                    </div>
                                )}
                                {detailsModalData?.experience && (
                                    <div className='requirementSubHeader'>
                                        <h4>Experience</h4>
                                        <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: detailsModalData.experience }}></div>
                                    </div>
                                )}
                                {detailsModalData?.credentialing && (
                                    <div className='requirementSubHeader'>
                                        <h4>Credentialing (Required/Optional)</h4>
                                        <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: detailsModalData.credentialing }}></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {detailsModalData?.position_reports_to && (
                        <div className='boxContent'>
                            <div className='boxTitle'>
                                <h4>
                                    SALARY RANGE
                                </h4>
                            </div>
                            <div className='boxInnerContent'>
                                <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: detailsModalData.position_reports_to }}></div>
                            </div>
                        </div>
                    )}
                    {detailsModalData?.career_path_moves_from_this_role && (
                        <div className='boxContent'>
                            <div className='boxTitle'>
                                <h4>
                                    CAREER PATH MOVES FROM THIS ROLE
                                </h4>
                            </div>
                            <div className='boxInnerContent'>
                                <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: detailsModalData.career_path_moves_from_this_role }}></div>
                            </div>
                        </div>
                    )}
                    {detailsModalData?.career_category_text && (
                        <div className='boxContent'>
                            <div className='boxTitle'>
                                <h4>
                                    CAREER CATEGORIES
                                </h4>
                            </div>
                            <div className='boxInnerContent'>
                                <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: detailsModalData.career_category_text }}></div>
                            </div>
                        </div>
                    )}
                </Col>
            )}
        </Row>
    </CommonModal></>);
}

export default CareerDetailsModal;
import { Button, Checkbox, Col, Divider, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
// import CountUp from "react-countup";
import "./Style/DeshBoardCounter.less"
import BreadcrumbComponent from '../CommonComponent/BreadcrumbComponent';
import { DashboardBreadcrumb } from '../../../config/BreadcrumbConfig';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/app';
import { Notification, contentMarkupWithClass, snakeCaseString } from "../../../config/Global";
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as SkillAsse } from "../../../assets/webimage/skillExam.svg";
// import { ReactComponent as InterAsse } from "../../../assets/webimage/InterestExam.svg";
import { ReactComponent as Interest } from "../../../assets/webimage/Intrest.svg";
import { ReactComponent as Skill } from "../../../assets/webimage/test.svg";
import { ReactComponent as Result } from "../../../assets/webimage/result_icon.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/icon/dashboardRightArrow.svg";
import { ReactComponent as AllCareer } from "../../../assets/webimage/AllCareer.svg";
import { disabledWelcomeModal, getExamList, setGoSkillModal } from './Components/Utils/slice';
import CommonModal from '../../../components/Modals/CommonModal';
import { setUserOptions } from '../../../store/AuthSlice';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { EnvironmentFilled } from '@ant-design/icons';
import { ReactComponent as StronglyAgree } from "../../../assets/images/icon/examAnswerIcon/Agree.svg";
import { ReactComponent as Agree } from "../../../assets/images/icon/examAnswerIcon/stronglyAgree.svg";
import { ReactComponent as Unsure } from "../../../assets/images/icon/examAnswerIcon/Unsure.svg";
import { ReactComponent as StronglyDisagree } from "../../../assets/images/icon/examAnswerIcon/StronglyDisagree.svg";
import { ReactComponent as Disagree } from "../../../assets/images/icon/examAnswerIcon/Disagree.svg";

const { Title } = Typography;

/**
 * Renders the Dashboard component of the application.
 *
 * @return {JSX.Element} The rendered component.
 */
const Index = () => {
    const { profileProgress, userDetail } = useSelector((state: RootState) => state.AUTH)
    const { goSkillModal } = useSelector((state: RootState) => state.WEBPROFILE)
    const navigate = useNavigate();
    const [interestOpenModal, setInterestOpenModal] = useState<boolean>(false);
    const [instructionInterest, setIntructionInterest] = useState<boolean>(false);
    const [examStartLoading, setExamStartLoading] = useState<boolean>(false);
    const [skillOpenModal, setSkillOpenModal] = useState<boolean>(false);
    const [skillInstructionModal, setSkillInstructionModal] = useState<boolean>(false);
    const [resultOpenModal, setResultOpenModal] = useState<boolean>(false);
    const [sureStartExam, setSureStartExam] = useState<boolean>(false);
    const [openWelcomeModal, setOpenWelcomeModal] = useState<boolean>(true);
    const [welcomeValue, setWelcomeValue] = useState<any>();
    const [feedBackModal, setFeedBackModal] = useState<any>("false")
    const dispatch: AppDispatch = useDispatch();
    const [options, setOption] = useState<any>();
    const svgIcons: any = {
        strongly_agree: <StronglyAgree />,
        agree: <Agree />,
        neutral: <Unsure />,
        disagree: <Disagree />,
        strongly_disagree: <StronglyDisagree />,
    };

    const initialData: any = sessionStorage.getItem("initialData");
    const welcomeData: any = sessionStorage.getItem("welcomeData") || null;
    const initialDataJson = JSON.parse(initialData);
    const initialStoreData: any = useSelector((state: RootState) => state.AUTH.initialData);

    useEffect(() => {
        dispatch(setUserOptions()).catch((e: any) => e)
        if (instructionInterest || interestOpenModal) {
            if (initialDataJson) {
                setOption(initialDataJson?.interest_assessment_options_type)
            } else {
                setOption(initialStoreData?.interest_assessment_options_type)
            }
        } else {
            if (initialDataJson) {
                setOption(initialDataJson?.start_exam_experience_levels)
            } else {
                setOption(initialStoreData?.start_exam_experience_levels)
            }
        }
        const feedBackModalData: any = localStorage.getItem("feedback")
        if (feedBackModalData) {
            setFeedBackModal(feedBackModalData)
        }
    }, [initialData,interestOpenModal, instructionInterest,skillOpenModal,skillInstructionModal])


    const closeFeedBackModal = () => {
        localStorage.removeItem("feedback")
        setFeedBackModal("false")
    }

    // Functions to close different modals : start
    const closeModal = () => {
        setInterestOpenModal(false)
        setSkillOpenModal(false)
        setResultOpenModal(false)
        setExamStartLoading(false)
        dispatch(setGoSkillModal(false))
    }

    const closeInstructionModal = () => {
        setExamStartLoading(false)
        if(interestOpenModal){
            setIntructionInterest(false);
        }
        else{
            setSkillInstructionModal(false)
        }   
    }

    const closeSureStartExam = () => {
        setSureStartExam(false)
        setExamStartLoading(false)
    }
    const closeWelcomeModal = () => {
        if (welcomeValue) {
            dispatch(disabledWelcomeModal()).catch((e: any) => e)
        }
         sessionStorage.setItem("welcomeData",'yes');
        setOpenWelcomeModal(false)
    }
    // Functions to close different modals : end

    const sureExamModalContinue = () =>{
        if(interestOpenModal){
            setIntructionInterest(true)
        }
        else{
            setSkillInstructionModal(true)
        }
    }
    
    // Function to show Notification if profile is not complete else open exam modal: start
    const interest = () => {
        if (Number(profileProgress) >= 90) {
            setInterestOpenModal(true)
        } else {
            navigate("/user-profile")
            Notification.error({
                placement: "top",
                message: "Profile must be complete prior to taking assessments."
            })

        }
    }

    const skill = () => {
        // navigate("/my-skill-assessment")
        if (Number(profileProgress) >= 90) {
            setSkillOpenModal(true)

        } else {
            navigate("/user-profile")
            Notification.error({
                placement: "top",
                message: "Profile must be complete prior to taking assessments."
            })

        }
    }
    // Function to show Notification if profile is not complete else open exam modal: End

    // To show result if both exam are completed.
    const result = () => {
        if (userDetail?.result_status === 1) {
            navigate("/report")

        } else {
            setResultOpenModal(true)
        }
    }
    
    const location = () => {
        navigate("/locations")
    }

    // To navigate to exam page in case of  new exam
    const startExams = () => {
        setExamStartLoading(true)
        if (instructionInterest || interestOpenModal) {
            navigate("/interest-assessment-exam")
        } else {
            navigate("/skill-assessment-exam")
        }
    }

    // To navigate to exam page in case of  pending exam
    const pendingExam = () => {
        if (interestOpenModal) {
            dispatch(getExamList("2")).then((data: any) => {
                const base64EncodedId = btoa(data?.data?.id);
                navigate(`/interest-assessment-exam-pending/${base64EncodedId}`)
            })
                .catch((e: any) => e)

        } else {
            dispatch(getExamList("1")).then((data: any) => {
                const base64EncodedId = btoa(data?.data?.id);
                navigate(`/skill-assessment-exam-pending/${base64EncodedId}`)
            })
                .catch((e: any) => e)
        }

    }

    // To navigate to exam page in case of completed exam
    const viewExam = () => {

        if (interestOpenModal) {
            dispatch(getExamList("2")).then((data: any) => {
                const base64EncodedId = btoa(data?.data?.id);
                navigate(`/interest-assessment-exam/${base64EncodedId}`)
            })
                .catch((e: any) => e)
        } else {
            dispatch(getExamList("1")).then((data: any) => {
                const base64EncodedId = btoa(data?.data?.id);
                navigate(`/skill-assessment-exam/${base64EncodedId}`)
            })
                .catch((e: any) => e)
        }


    }
    const goToSkillExam = () => {
        dispatch(setGoSkillModal(false))
        setSkillOpenModal(true)
    }


    const goToAllCareers = () => {
        navigate(`/careers`);
    }


    return (
        <section className='web-dashboard-section'>
            <div className='container'>
                <div className='breadcrumb'>
                    <BreadcrumbComponent key={1} items={DashboardBreadcrumb.path} />
                </div>
                <Divider ><Title level={3} style={{  color: '#1DAB3B' }}>Career Discovery Process</Title></Divider>
                <Row gutter={[30, 35]}>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={8}>

                        <div className='web-dashboard-counter' onClick={() => interest()}>
                            <div className='web-dashboard-counter-exam'>
                                <div className='web-dashboard-counter-exam-content'>
                                    <Interest />
                                    <p>
                                        Interest Discovery
                                    </p>
                                </div>
                            </div>
                            <div className='web-dashboard'>
                                <div className='rightArrow'>
                                    <RightArrow />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                        <div
                            className={`web-dashboard-counter
                             ${((userDetail?.interest_assessment_exam_status === 0 || userDetail?.interest_assessment_exam_status === 1) && userDetail?.last_skills_exam_id === 0) && "disabledBox"}`}
                            onClick={skill}>
                            <div className='web-dashboard-counter-exam'>
                                <div
                                    className='web-dashboard-counter-exam-content' >
                                    <Skill />
                                    <p>
                                        Skill Portrait
                                    </p>
                                </div>
                            </div>
                            <div className='web-dashboard'>
                                <div className='rightArrow'>

                                    <RightArrow />
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                        <div
                            onClick={result}
                            className={
                                (userDetail?.result_status === 1)
                                    ? " web-dashboard-counter"
                                    : "disabledBox web-dashboard-counter"
                            }
                        >
                            <div className='web-dashboard-counter-exam '>
                                <div className='web-dashboard-counter-exam-content'>
                                    <Result />
                                    <p>
                                        Career Compass
                                    </p>
                                </div>
                            </div>
                            <div className='web-dashboard'>
                                <div className='rightArrow'>

                                    <RightArrow />
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>
                <Divider ><Title level={3}  style={{   color: '#1DAB3B' }}>Resources</Title></Divider>
                
                <Row gutter={[30, 35]}>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                        <div
                            onClick={goToAllCareers}
                            className='web-dashboard-counter  ' >
                            <div className='web-dashboard-counter-exam'>
                                <div className='web-dashboard-counter-exam-content'>
                                    <AllCareer />
                                    <p>
                                        All Careers
                                    </p>
                                </div>
                                {/* <p className='comingSoonExam'> Coming soon! </p> */}
                            </div>
                            <div className='web-dashboard'>
                                <div className='rightArrow'>

                                    <RightArrow />
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={8} onClick={location} >
                        <div className='web-dashboard-counter ' >
                            <div className='web-dashboard-counter-exam'>
                                <div className='web-dashboard-counter-exam-content'>
                                    <EnvironmentFilled />
                                    <p>
                                        Locations
                                    </p>
                                </div>
                            </div>
                            <div className='web-dashboard'>
                                <div className='rightArrow'>

                                    <RightArrow />

                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>

            {/* Interest section start */}
            <CommonModal
                title={"Interest Discovery"}
                open={interestOpenModal}
                onCancel={closeModal}
                className='startExamModal'
            >
                {
                     userDetail?.interest_assessment_exam_status === 0
                     ?
                     <>
                         <h2 className='instructionTitle'>Here’s how the Interest Discovery works…</h2>
                         <p>The Interest Discovery has 50 questions about work activities that some people do on their jobs. Read each question carefully and decide how you would feel about doing each type of work:</p>
                         {options?.map((contentItem: any, index: number) => {
                             return (
                                 <div key={index} className='instructionEmoji'>
                                     {svgIcons[snakeCaseString(contentItem.title)?.replace(" ", "")]}=<span>{contentItem.title}</span>
                                 </div>
                             )
                         })}
                         <p>Take your time answering the questions. You are learning about your interests, so that you can explore careers you might like and find rewarding!</p>
                         <p>This assessment takes approximately 10 minutes to complete. Your progress will be automatically saved.</p>
                         <div className='examStartModal'>
                             <Button
                                 key="2"
                                 htmlType="button"
                                 className='common_button green_border_common_btn'
                                 onClick={() => startExams()}
                                 loading={examStartLoading}

                             >
                                 Continue
                             </Button>
                         </div>
                     </>
                     :
                        userDetail?.interest_assessment_exam_status === 1
                            ?
                            <>
                                <p>There is currently an assessment in progress. Would you like to continue your assessment or start over?</p>
                                <div className='examStartModal'>
                                    <Button
                                        key="1"
                                        htmlType="button"
                                        className='common_button green_border_common_btn'
                                        onClick={() => pendingExam()}

                                    >
                                        Resume assessment
                                    </Button>

                                    <Divider>OR</Divider>

                                    <Button
                                        key="2"
                                        htmlType="button"
                                        className='common_button green_border_common_btn'
                                        onClick={() => setSureStartExam(true)}
                                    >
                                        Begin new assessment
                                    </Button>
                                </div>
                            </>
                            :
                            <>
                                <p>Retaking the assessment may impact your results.</p>
                                <div className='examStartModal'>
                                    <Button
                                        key="2"
                                        htmlType="button"
                                        className='common_button green_border_common_btn'
                                        onClick={() => setIntructionInterest(true)}
                                        loading={examStartLoading}
                                    >
                                        Continue
                                    </Button>
                                    <Divider>OR</Divider>
                                    <Button
                                        key="1"
                                        htmlType="button"
                                        className='common_button green_border_common_btn'
                                        onClick={() => viewExam()}
                                    >
                                        View Exam
                                    </Button>
                                </div>
                            </>
                }

            </CommonModal>
            <CommonModal
                title={interestOpenModal?"Interest Discovery":"Skill Discovery"}
                open={sureStartExam}
                onCancel={closeSureStartExam}
                className='startExamModal'
            >
                <p>Your progress will be lost if you choose to start a new assessment. Please select continue to begin.</p>
                <div className='examStartModal'>
                    <Button
                        key="2"
                        htmlType="button"
                        className='common_button green_border_common_btn'
                        onClick={sureExamModalContinue}
                        loading={examStartLoading}

                    >
                        Continue
                    </Button>
                </div>
            </CommonModal>

            <CommonModal
                title={"Interest Discovery"}
                open={instructionInterest}
                onCancel={closeInstructionModal}
                className='startExamModal'
            >
                <>
                            <h2 className='instructionTitle'>Here’s how the Interest Discovery works…</h2>
                            <p>The Interest Discovery has 50 questions about work activities that some people do on their jobs. Read each question carefully and decide how you would feel about doing each type of work:</p>
                            {options?.map((contentItem: any, index: number) => {
                                return (
                                    <div key={index} className='instructionEmoji'>
                                        {svgIcons[snakeCaseString(contentItem.title)?.replace(" ", "")]}=<span>{contentItem.title}</span>
                                    </div>
                                )
                            })}
                            <p>Take your time answering the questions. You are learning about your interests, so that you can explore careers you might like and find rewarding!</p>
                            <p>This assessment takes approximately 10 minutes to complete. Your progress will be automatically saved.</p>
                            <div className='examStartModal'>
                                <Button
                                    key="2"
                                    htmlType="button"
                                    className='common_button green_border_common_btn'
                                    onClick={() => startExams()}
                                    loading={examStartLoading}

                                >
                                    Continue
                                </Button>
                            </div>
                        </>
            </CommonModal>

            {/* Interest section end */}



            {/* skill section start */}
            <CommonModal
                title={"Skill Portrait"}
                open={skillOpenModal}
                onCancel={closeModal}
                className='startExamModal'
            >
                {

                    (userDetail?.interest_assessment_exam_status !== 2)
                        ?
                            <>
                                <p>Please complete your Interest Discovery prior to taking the Skill Portrait.</p>
                                {userDetail?.last_skills_exam_id !== 0 &&

                                    <div className='examStartModal'>
                                        <Button
                                            key="1"
                                            htmlType="button"
                                            className='common_button green_border_common_btn'
                                            onClick={() => viewExam()}
                                        >
                                            View exam
                                        </Button>
                                    </div>
                                }
                            </>
                        :
                        userDetail?.skill_assessment_exam_status === 0
                            ?
                            <>
                                <h2 className='instructionTitle'>Here’s how the Skill Portrait works…</h2>
                                <p>The Skill Portrait has about 25 questions about skills or competencies that are required for different careers. For each skill select the response that best describes your current skills or experience.</p>
                                {options?.map((contentItem: any, index: number) => {
                                    return (
                                        <div key={index} className='instructionEmoji'>
                                            <span className='skillRound' ></span>=<div dangerouslySetInnerHTML={contentMarkupWithClass("instructionEmojiContent", contentItem.name)}></div>
                                        </div>
                                    )
                                })}
                                <p>Take your time answering the questions. You are learning about your different skills, so that you can explore careers you might like and find rewarding!</p>
                                <p>This assessment takes approximately 10 minutes to complete. Your progress will be automatically saved.</p>
                                <div className='examStartModal'>
                                    <Button
                                        key="2"
                                        htmlType="button"
                                        className='common_button green_border_common_btn'
                                        onClick={() => startExams()}
                                        loading={examStartLoading}

                                    >
                                        Continue
                                    </Button>
                                </div>
                            </>
                            :
                            userDetail?.skill_assessment_exam_status === 1
                                ?
                                <>
                                    <p>There is currently an assessment in progress. Would you like to continue your assessment or start over?</p>
                                    <div className='examStartModal'>
                                        <Button
                                            key="1"
                                            htmlType="button"
                                            className='common_button green_border_common_btn'
                                            onClick={() => pendingExam()}

                                        >
                                            Resume assessment
                                        </Button>

                                        <Divider>OR</Divider>

                                        <Button
                                            key="2"
                                            htmlType="button"
                                            className='common_button green_border_common_btn'
                                            onClick={() => setSureStartExam(true)}
                                        >
                                            Begin new assessment
                                        </Button>
                                    </div>
                                </>
                                :
                                (userDetail?.skill_assessment_exam_status === 2 || userDetail?.skill_assessment_exam_status === 4) ?
                                    <>
                                        <p>Please complete your Interest Discovery prior to taking the Skill Portrait.</p>
                                        <div className='examStartModal'>
                                            <Button
                                                key="1"
                                                htmlType="button"
                                                className='common_button green_border_common_btn'
                                                onClick={() => viewExam()}
                                            >
                                                View exam
                                            </Button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <p>Retaking the assessment may impact your results.</p>
                                        <div className='examStartModal'>
                                            <Button
                                                key="2"
                                                htmlType="button"
                                                className='common_button green_border_common_btn'
                                                onClick={() => setSkillInstructionModal(true)}
                                            >
                                                Begin new assessment
                                            </Button>
                                            <Divider>OR</Divider>
                                            <Button
                                                key="1"
                                                htmlType="button"
                                                className='common_button green_border_common_btn'
                                                onClick={() => viewExam()}
                                            >
                                                View exam
                                            </Button>
                                        </div>
                                    </>
                }
            </CommonModal>

            <CommonModal
                title={"Skill Portrait"}
                open={skillInstructionModal}
                onCancel={closeInstructionModal}
                className='startExamModal'
            >
                <>
                                <h2 className='instructionTitle'>Here’s how the Skill Portrait works…</h2>
                                <p>The Skill Portrait has about 25 questions about skills or competencies that are required for different careers. For each skill select the response that best describes your current skills or experience.</p>
                                {options?.map((contentItem: any, index: number) => {
                                    return (
                                        <div key={index} className='instructionEmoji'>
                                            <span className='skillRound' ></span>=<div dangerouslySetInnerHTML={contentMarkupWithClass("instructionEmojiContent", contentItem.name)}></div>
                                        </div>
                                    )
                                })}
                                <p>Take your time answering the questions. You are learning about your different skills, so that you can explore careers you might like and find rewarding!</p>
                                <p>This assessment takes approximately 10 minutes to complete. Your progress will be automatically saved.</p>
                                <div className='examStartModal'>
                                    <Button
                                        key="2"
                                        htmlType="button"
                                        className='common_button green_border_common_btn'
                                        onClick={() => startExams()}
                                        loading={examStartLoading}

                                    >
                                        Continue
                                    </Button>
                                </div>
                            </>
            </CommonModal>
            {/* skill section end */}


            {/* result section start */}
            <CommonModal
                title={"Career Compass"}
                open={resultOpenModal}
                onCancel={closeModal}
                className='startExamModal'
            >
                <p>Please complete your Interest Discovery and Skill Portrait to receive your Career Compass.</p>
                <div className='examStartModal'>
                    <Button
                        key="2"
                        htmlType="button"
                        className='common_button green_border_common_btn'
                        onClick={() => closeModal()}
                    >
                        Close
                    </Button>
                </div>
            </CommonModal>
            {/* result section end */}



            {/* welcomeModal  */}
             {userDetail?.popup_disabled === 0 && welcomeData !='yes' &&
                <CommonModal
                    title={"Welcome"}
                    open={openWelcomeModal}
                    onCancel={() => closeWelcomeModal()}
                    className='startExamModal'
                    centered
                    width={600}
                    footer={
                        <div>
                            <Checkbox
                                onChange={(e: CheckboxChangeEvent) => {
                                    setWelcomeValue(e.target.checked)
                                }}
                            >
                                {"Please do not show this again."}
                            </Checkbox>
                        </div>
                    }
                >
                    <p>
                        Welcome to your launchpad for aligning your interests and skills with the offshore wind industry. Start with our Interest Discovery, evaluate your existing skills, and receive a Career Compass that highlights potential career paths within the industry. Your adventure in offshore wind begins now!
                    </p>
                </CommonModal>
            }

            {/* this modal open after complete interest exam. use for going to Skill Portrait --- start */}

            <CommonModal
                title={"Interest Discovery "}
                open={goSkillModal}
                onCancel={() => closeModal()}
                className='startExamModal'
            >

                <p>You have successfully completed your Interest Discovery! You may now move on to your Skill Portrait!</p>
                <div className='examStartModal'>
                    <Button
                        key="2"
                        htmlType="button"
                        className='common_button green_border_common_btn'
                        onClick={() => goToSkillExam()}

                    >
                        Continue
                    </Button>
                </div>
            </CommonModal>

            {/* this modal open after complete interest exam. use for going to Skill Portrait --- end */}



            {/*start ---- this modal for when user out from result pages */}
            <CommonModal
                title={"Feedback"}
                open={feedBackModal === "true"}
                onCancel={() => closeFeedBackModal()}
                width={650}
                className='startExamModal feedBack'
            >

                <div className='feedBack'>
                    <p>Have any feedback about this experience? Share it with us: </p>
                    <div className='link'>
                        <a href='https://www.surveymonkey.com/r/energycareernavigator'  rel="noreferrer" target="_blank" >https://www.surveymonkey.com/r/energycareernavigator</a>
                    </div>
                </div>
                <div className='examStartModal'>
                    <Button
                        key="2"
                        htmlType="button"
                        className='common_button green_border_common_btn'
                        onClick={() => closeFeedBackModal()}
                    >
                        Close
                    </Button>
                </div>
            </CommonModal>
            {/*end ---- this modal for when user out from result pages */}
        </section>
    )
}

export default Index
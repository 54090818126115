import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store/app';
import { Button, Col, Row, Space, Table, Tooltip } from 'antd';
import { ArrowRightOutlined, StarFilled } from '@ant-design/icons';
import { addRemoveJob, fetchSavedJobs } from '../Utils/slice';
import BreadcrumbComponent from '../../../CommonComponent/BreadcrumbComponent';
import { SavedJobsBreadcrumb } from '../../../../../config/BreadcrumbConfig'
import CareerDetailsModal from '../AllCareers/components/CareerDetailsModal';
// import { Col, Image, Progress, Row, Spin, Tooltip } from 'antd'
// import { DownloadOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
// import CommonModal from '../../../../../components/Modals/CommonModal';
// import { ReactComponent as GraphImage } from "../../../../../assets/images/icon/Figpie.svg";
// import { disabledWelcomeModal, downloadReportPdf, examResult } from '../Utils/slice'
// import { useDispatch, useSelector } from 'react-redux'
// import { AppDispatch, RootState } from '../../../../../store/app'
// import PageSpinner from '../../../../../components/PageSpinner/PageSpinner'
// import { WebConstant } from '../../../webConstant/WebConstant';
// import { b64toBlob } from '../../../../../config/Global'


const SavedJobs = () => {

    const savedjobs = useSelector((state: RootState) => state.WEBPROFILE.savedJobList);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch: AppDispatch = useDispatch();


    useEffect(() => {
        ReloadTable();
        // setLoading(true);
        // dispatch(fetchSavedJobs()).then((data: any) => {
        //     setLoading(false)
        // }).catch((e: any) => {
        //     setLoading(false)
        // })


    }, [])

    const [detailsModalId, setDetailsModalId] = useState<any>(null);

    // const [detailsModalData, setDetailsModalData] = useState<any>();
    // const [detailsModalTitle, setDetailsModalTitle] = useState<any>();
    // const [detailsModal, setDetailsModal] = useState<any>(false)


    // const showCareerDetails = (career_id: any) => {
    //     setLoading(true);
    //     dispatch(fetchCareerDetails(career_id)).then((data: any) => {
    //         // console.log(data);

    //         // setDetailsModal(true)
    //         // setDetailsModalData(data.data)
    //         // setDetailsModalTitle(data.data.name)
    //         setLoading(false)
    //     }).catch((e: any) => {
    //         // setLoading(false)
    //     })
    // }


    const ReloadTable = () => {
        setLoading(true);
        // dispatch(fetchCareers(searchValue)).then((data: any) => {
        //     setLoading(false)
        // }).catch((e: any) => {
        //     setLoading(false)
        // });

        dispatch(fetchSavedJobs()).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

    }


    const SaveAndRemoveFromJobListProcess = (career_id: any, status: number) => {
        const requestData = {
            career_id: career_id,
            add: status
        }
        setLoading(true);
        dispatch(addRemoveJob(requestData)).then(() => {
            setLoading(false)
            ReloadTable();
        }).catch(() => {
            setLoading(false)
            ReloadTable();
        });
        return 123;
    }


    const columns = [
        {
            title: "Career Name",
            pinned: "left",
            width: "90%",
            key: 'name',
            field: "name",
            render: (item: any) => item.name,

        },
        {
            title: "",
            pinned: "left",
            width: "10%",
            key: 'name',
            field: "name",
            render: (item: any) => <>
                <Space align="center" size="large">
                    <Tooltip placement="top" title={'Remove from Saved List' } overlayClassName='examTooltip' >
                        <Button className="common_button green_border_common_btn rounded" icon={ <StarFilled /> } onClick={() => SaveAndRemoveFromJobListProcess(item.id, 0)} ></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={'Details'} overlayClassName='examTooltip' >
                        <Button className="common_button green_border_common_btn rounded" icon={<ArrowRightOutlined />} onClick={() => setDetailsModalId(item.id)} ></Button>
                    </Tooltip>
                </Space>
            </>

        },

    ];
    return (<>
        <section className='web-dashboard-section all-career-main'>
            <div className='container'>
                <div className='breadcrumb'>
                    <BreadcrumbComponent key={1} items={SavedJobsBreadcrumb.path} />
                </div>


                <div className="  ">

                    <Row gutter={10} >
                        <Col xs={{ span: 24 }} md={{ span: 24 }} xxl={{ span: 24, offset: 0 }} >
                            <Table
                                loading={loading}
                                // showHeader={false}
                                dataSource={savedjobs || []}
                                columns={columns}
                                pagination={{ defaultPageSize: 20, position: ["bottomCenter"], }}
                            />
                        </Col>

                    </Row>

                </div>
            </div>
        </section>
        <CareerDetailsModal career_id={detailsModalId} onCancel={() => setDetailsModalId(null)} />

    </>)
}

export default SavedJobs;


import React from "react";
import { useRoutes } from "react-router-dom";
import AuthLayout from "../views/layouts/AuthLayout";
import MainLayout from "../views/layouts/MainLayout";
import PageNotFound from "../views/errors/PageNotFound";
// import ResetPassword from "../views/modules/Auth/ResetPassword";
// import ForgotPassword from "../views/modules/Auth/ForgotPassword";
import HomePage from "../views/webPages/HomePage";
import WebLogin from "../views/webPages/Loginpages/webLogin/WebLogin";
import Register from "../views/webPages/Loginpages/Register/Register";
import ForgotPasswordWeb from "../views/webPages/Loginpages/ForgotPassword/ForgotPassword";
import Index from "../views/webPages/WebDashBoard/Index";
import UserProfile from "../views/webPages/WebDashBoard/Components/UserProfile/UserProfile";
import ChangePassword from "../views/webPages/Loginpages/ChangePassword/ChangePassword";
import Cmspages from "../views/webPages/CmsPages/Index";
import VRTrainingLocation from "../views/webPages/WebDashBoard/Components/VRTrainingLocation/VRTrainingLocation";
// import InterestAssessment from "../views/webPages/WebDashBoard/Components/InterestAssessment/Index";
// import SkillAssessment from "../views/webPages/WebDashBoard/Components/SkillAssessment/Index";
import ProfileChangePassword from "../views/webPages/WebDashBoard/Components/ProfileChangePassword/ProfileChangePassword";
import InterestAssessmentAdd from "../views/webPages/WebDashBoard/Components/InterestAssessment/InterestAssessmentAdd";
import InterestAssessmentView from "../views/webPages/WebDashBoard/Components/InterestAssessment/InterestAssessmentView";
import SkillAssessmentAdd from "../views/webPages/WebDashBoard/Components/SkillAssessment/SkillAssessmentAdd";
import SkillAssessmentView from "../views/webPages/WebDashBoard/Components/SkillAssessment/SkillAssessmentView";
import InterestAssessmentPending from "../views/webPages/WebDashBoard/Components/InterestAssessment/InterestAssessmentPending";
import SkillAssessmentPending from "../views/webPages/WebDashBoard/Components/SkillAssessment/SkillAssessmentPending";
import Result from "../views/webPages/WebDashBoard/Components/Result/Result";
import VerifyAccount from "../views/webPages/Loginpages/VerifyAccount/VerifyAccount";
import AllCareers from "../views/webPages/WebDashBoard/Components/AllCareers";
import SavedJobs from "../views/webPages/WebDashBoard/Components/SavedJobs/SavedJobs";

export default function Router() {
    return useRoutes(RouterConfig);
}

export const RouterConfig = [
    {
        path: "/about-us",
        element: <Cmspages />
    },
    {
        path: "/terms-of-use",
        element: <Cmspages />
    },
    {
        path: "/privacy-policy",
        element: <Cmspages />
    },
    {
        path: "/",
        element: <HomePage />,
    },
    {
        element: <AuthLayout />,
        children: [
            {
                path: "/login",
                element: <WebLogin />,
            },
            {
                path: "/register",
                element: <Register />,
            },
            {
                path: "/register/:user_id",
                element: <Register />,
            },
            {
                path: "/register/link/:access_code_id",
                element: <Register />,
            },
            {
                path: "/forgot-password",
                element: <ForgotPasswordWeb />,
            },
            {
                path: "/reset/:otp",
                element: <ChangePassword />,
            },
            {
                path: "/verify-account/:token",
                element: <VerifyAccount />,
            },            
        ],
    },
    {
        element: <MainLayout />,
        children: [
            {
                path: "/dashboard",
                element: <Index />,
            },
            {
                path: "/user-profile",
                element: <UserProfile />,
            },
            {
                path: "/change-password",
                element: <ProfileChangePassword />,
            },
            {
                path: "/locations",
                element: <VRTrainingLocation />
            },
            {
                path: "/interest-assessment-exam",
                element: <InterestAssessmentAdd />
            },
            {
                path: "/interest-assessment-exam/:id",
                element: <InterestAssessmentView />
            },
            {
                path: "/interest-assessment-exam-pending/:id",
                element: <InterestAssessmentPending />
            },
            {
                path: "/skill-assessment-exam",
                element: <SkillAssessmentAdd />
            },
            {
                path: "/skill-assessment-exam/:id",
                element: <SkillAssessmentView />
            },
            {
                path: "/skill-assessment-exam-pending/:id",
                element: <SkillAssessmentPending />
            },
            {
                path: "/report",
                element: <Result />
            },
            {
                path: "/careers",
                element: <AllCareers />
            },
            {
                path: "/careers/:career_category",
                element: <AllCareers />
            },
            {
                path: "/saved-careers",
                element: <SavedJobs />
            },

        ],
    },
    {
        path: "*",
        element: <PageNotFound />,
    },
];

import React, { useEffect, useState } from 'react'
import MultipleMarker from '../../../../../components/Map/MultipleMarkerMap'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store/app';
import { fetchVrTraining } from '../Utils/slice';
import PageSpinner from '../../../../../components/PageSpinner/PageSpinner';
import BreadcrumbComponent from '../../../CommonComponent/BreadcrumbComponent';
import { LocationsBreadcrumb } from '../../../../../config/BreadcrumbConfig';
import greenImg from "../../../../../assets/webimage/Green-Location-icon.png"
import blueImg from "../../../../../assets/webimage/Blue-Location-icon.png"
// import redImg from "../../../../../assets/webimage/Red-Location-icon.png"

/**
 * Renders the VR Training Location component.
 *
 * @return {JSX.Element} The rendered VR Training Location component.
 */
const VRTrainingLocation = () => {
	const dispatch: AppDispatch = useDispatch()
	const { mapScriptLoaded } = useSelector((state: RootState) => state.AUTH);
	const vrData = useSelector((state: RootState) => state.WEBPROFILE.vrTraining)
	const [loading, setLoading] = useState(true);
	// const marker_icon = redImg
	const marker_icon = blueImg
	const green = greenImg

	useEffect(() => {
		dispatch(fetchVrTraining())
			.then(() => {
				setLoading(false)
			})
			.catch((error: any) => error)
	}, [mapScriptLoaded])

	return !loading ? (
		<>
			<div className="container">
				<div className="breadcrumb">
					<BreadcrumbComponent
						key={1}
						items={LocationsBreadcrumb.path}
					/>
				</div>
				<div className='pb-15'>
					<img src={marker_icon} alt='Training Location'/> <span>Training Location</span>
					<img src={green} alt='Career Location' className='ml-40'/> <span>Career Location</span>
				</div>
			</div>
			
			<MultipleMarker latLong={vrData} />
		</>
	) : <PageSpinner />
}

export default VRTrainingLocation